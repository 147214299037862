import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'projects/vl/src/environments/environment';
import { UPUtilityService } from 'up';

@Component({
  selector: 'vl-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  apiErrorConfig = {
    heading: 'GENERIC.ERROR.HEADING',
    message: 'GENERIC.ERROR.MESSAGE',
    buttonText: 'GENERIC.ERROR.EXIT_BUTTON',
    moreDetails: '',
  };
  constructor(
    private upUtilityService: UPUtilityService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    const errorPageTitle =
      this.translate.instant('GENERIC.PAGE_TITLE') +
      this.translate.instant('GENERIC.ERROR.PAGE_TITLE');
    this.upUtilityService.setPageTitle(errorPageTitle);
  }

  onExitClick() {
    this.upUtilityService.returnToCourse(`${environment.apiUrl}/vl`);
  }
}
